<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-row no-gutters class="img " align="center" justify="center">
      <template
        v-if="!loading && (contenttype == 'image' || extensType == 'jfif')"
      >
        <v-img
          height="130"
          :src="urlimg"
          max-width="140"
          @click="dialog = true"
        >
        </v-img>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <p v-on="on" class="font-weight-medium namefile">{{ imageName }}</p>
          </template>
          <span>{{ imageName }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn text v-on="on" color="error" @click="gotoDelete(true)" ><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Delete Image</span>
        </v-tooltip>
      
      </template>
      <template
        v-else-if="
          !loading && contenttype == 'application' && extensType != 'jfif'
        "
      >
        <v-img
          height="130"
          :src="require('@/assets/img/documents.jpg')"
          max-width="120"
          @click="getPdf"
        >
        </v-img>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <p v-on="on" class="font-weight-medium namefile">{{ imageName }}</p>
          </template>
          <span>{{ imageName }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn text v-on="on" color="error" @click="gotoDelete(true)" ><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Delete Document</span>
        </v-tooltip>
      </template>
      <template v-else-if="loading && contenttype == 'image'">
        <div class="d-flex justify-center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
      </template>
      <template v-else>
        <div class="no-image"><h4>No image</h4></div>
      </template>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <div class="dialogimg">
        <template v-if="contenttype == 'application' && extensType != 'jfif'">
          <v-img :src="require('@/assets/img/documents.jpg')" contain></v-img>
          <p class="font-weight-medium text-center white--text nameindialog">
            {{ imageName }}
          </p>
        </template>
        <template v-else>
          <v-img :src="urlimg" contain></v-img>
          <p class="font-weight-medium text-center white--text nameindialog">
            {{ imageName }}
          </p>
        </template>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              v-on="on"
              small
              class="btnimg mx-1 my-2"
              @click="dialog = false"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              v-on="on"
              color="primary"
              small
              :class="
                imageFrom == 'upload'
                  ? 'btnimgd mx-1 my-2'
                  : ' btnimgdsms mx-1 my-2'
              "
              @click="downloadUrl"
              ><v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>{{
            contenttype == "application" && extensType != "jfif"
              ? "Download Documents"
              : "Download Image"
          }}</span>
        </v-tooltip>
        <template v-if="imageFrom == 'upload'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                color="error"
                small
                class="btnimgdelete mx-1 my-2"
                @click="gotoDelete(true)"
                ><v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{
              contenttype == "application" && extensType != "jfif"
                ? "Delete Documents"
                : "Delete Image"
            }}</span>
          </v-tooltip>
        </template>
      </div>
    </v-dialog>
    <v-dialog
    v-model="dialogconfirm"
   
    width="400"
    content-class="rounded-xl"
    persistent
  >

    <v-card>
      <v-card-title class="text-h6 font-weight-regular rounded-xl">
        {{ 'Delete' }}
      </v-card-title>

      <v-card-text column>
        <div class="text-body-1 text-truncate d-flex mb-1">
          {{ 'Are you sure you want to delete this item?' }}
        </div>
      </v-card-text>
      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn
          color="success"
          :disabled="loading"
          width="100"
          text
          rounded
          @click="gotoDelete(false)"
        >
          {{ 'Cancel' }}
        </v-btn>
        <v-btn
          color="red"
          :loading="loading"
          @click="deleteImage"
          width="100"
          depressed
          rounded
          dark
        >
          {{ 'Delete'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    
    <document-viewer
      v-if="showDocuments"
      v-model="showDocuments"
      :documents="documentPdf"
    />
  </div>
</template>
<script>
import axios from "axios";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo, notifySuccess } from "../Notification";
import DocumentViewer from "@/views/PdfSign/DocumentViewer.vue";
import { apiBaseUrl } from "@/enviorment";
export default {
  name: "card-image",
  props: {
    uuid: String,
    url: String,
    active: Boolean,
    imageFrom: String,
    imagesfrom: String,
    imagePath: String,
    imageName: String,
    originalUrl: String,
  },
  components: {
    DocumentViewer,
  },
  data() {
    return {
      showDocuments: false,
      overlay: false,
      dialog: false,
      loading: false,
      dialogconfirm:false,
      urlimg: "",
      typeimage: "",
      contenttype: "",
      extensType: "",
      documentPdf: [],
    };
  },
  methods: {
    getImage() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      this.loading = true;

      axios({
        method: "get",
        url: this.url,
        responseType: "blob",
        headers: auth,
      })
        .then((res) => {
          this.contenttype = res.data.type.split("/")[0];
          this.typeimage = res.data.type.split("/")[1];
          const buffer = new Blob([res.data]);
          this.urlimg = URL.createObjectURL(buffer);

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    getPdf() {
      this.documentPdf = [];
      const pdf = {
        title: this.imageName,
        url: this.url,
      };
      this.documentPdf.push(pdf);

      this.showDocuments = true;
    },

    downloadUrl() {
      let imgs = this.imageName.split(".")[0];
      if (this.extensType == "jfif") {
        imgs = imgs + "." + "jpg";
      } else {
        imgs = imgs + "." + this.typeimage;
      }

      const a = document.createElement("A");
      a.href = this.urlimg;
      a.target = "_blank";
      a.setAttribute("style", "display: none");
      a.download = imgs;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    gotoDelete(val){
      this.dialogconfirm=val;
    },

    deleteImage() {
      let parameters = {};
      this.loading=true;
      if (this.imagesfrom == "lead") {
        {
          parameters = { leadUuid: this.uuid, path: this.imagePath };
        }
      } else {
        parameters = { patientUuid: this.uuid, path: this.imagePath };
      }

      getAPI
        .delete(`/social/deletePicture`, { params: parameters })
        .then(() => {
          notifyInfo("Image has been deleted");
          this.$emit("deleted");
          this.dialog = false;
          this.loading=false;
          this.dialogconfirm=false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  mounted() {
    this.extensType = this.imagePath.split(".")[1];
    this.getImage();
  },
};
</script>
<style lang="scss" scoped>
.img {
  margin: 5px;
}
.dialogimg {
  position: relative;
}
.btnimg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
.btnimgd {
  position: absolute;
  top: 0;
  right: 90px;
  z-index: 999;
}
.namefile {
  margin-top: 5px !important;
  width: 200px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nameindialog {
  margin: 0 8%;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btnimgdsms {
  position: absolute;
  top: 0;
  right: 45px;
  z-index: 999;
}
.btnimgdelete {
  position: absolute;
  top: 0;
  right: 45px;
  z-index: 999;
}

.no-image {
  display: flex;
  height: 120px;
  align-items: center;
}
</style>
