var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"img ",attrs:{"no-gutters":"","align":"center","justify":"center"}},[(!_vm.loading && (_vm.contenttype == 'image' || _vm.extensType == 'jfif'))?[_c('v-img',{attrs:{"height":"130","src":_vm.urlimg,"max-width":"140"},on:{"click":function($event){_vm.dialog = true}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('p',_vm._g({staticClass:"font-weight-medium namefile"},on),[_vm._v(_vm._s(_vm.imageName))])]}}],null,false,3459374528)},[_c('span',[_vm._v(_vm._s(_vm.imageName))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.gotoDelete(true)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,986913092)},[_c('span',[_vm._v("Delete Image")])])]:(
        !_vm.loading && _vm.contenttype == 'application' && _vm.extensType != 'jfif'
      )?[_c('v-img',{attrs:{"height":"130","src":require('@/assets/img/documents.jpg'),"max-width":"120"},on:{"click":_vm.getPdf}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('p',_vm._g({staticClass:"font-weight-medium namefile"},on),[_vm._v(_vm._s(_vm.imageName))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.imageName))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.gotoDelete(true)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Delete Document")])])]:(_vm.loading && _vm.contenttype == 'image')?[_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)]:[_c('div',{staticClass:"no-image"},[_c('h4',[_vm._v("No image")])])]],2),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialogimg"},[(_vm.contenttype == 'application' && _vm.extensType != 'jfif')?[_c('v-img',{attrs:{"src":require('@/assets/img/documents.jpg'),"contain":""}}),_c('p',{staticClass:"font-weight-medium text-center white--text nameindialog"},[_vm._v(" "+_vm._s(_vm.imageName)+" ")])]:[_c('v-img',{attrs:{"src":_vm.urlimg,"contain":""}}),_c('p',{staticClass:"font-weight-medium text-center white--text nameindialog"},[_vm._v(" "+_vm._s(_vm.imageName)+" ")])],_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnimg mx-1 my-2",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.dialog = false}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Close")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.imageFrom == 'upload'
                ? 'btnimgd mx-1 my-2'
                : ' btnimgdsms mx-1 my-2',attrs:{"fab":"","color":"primary","small":""},on:{"click":_vm.downloadUrl}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.contenttype == "application" && _vm.extensType != "jfif" ? "Download Documents" : "Download Image"))])]),(_vm.imageFrom == 'upload')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnimgdelete mx-1 my-2",attrs:{"fab":"","color":"error","small":""},on:{"click":function($event){return _vm.gotoDelete(true)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,4198373333)},[_c('span',[_vm._v(_vm._s(_vm.contenttype == "application" && _vm.extensType != "jfif" ? "Delete Documents" : "Delete Image"))])])]:_vm._e()],2)]),_c('v-dialog',{attrs:{"width":"400","content-class":"rounded-xl","persistent":""},model:{value:(_vm.dialogconfirm),callback:function ($$v) {_vm.dialogconfirm=$$v},expression:"dialogconfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" "+_vm._s('Delete')+" ")]),_c('v-card-text',{attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 text-truncate d-flex mb-1"},[_vm._v(" "+_vm._s('Are you sure you want to delete this item?')+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"width":"100","text":"","rounded":""},on:{"click":function($event){return _vm.gotoDelete(false)}}},[_vm._v(" "+_vm._s('Cancel')+" ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loading,"width":"100","depressed":"","rounded":"","dark":""},on:{"click":_vm.deleteImage}},[_vm._v(" "+_vm._s('Delete')+" ")])],1)],1)],1),(_vm.showDocuments)?_c('document-viewer',{attrs:{"documents":_vm.documentPdf},model:{value:(_vm.showDocuments),callback:function ($$v) {_vm.showDocuments=$$v},expression:"showDocuments"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }